import Router from 'next/router';
import nextCookie from 'next-cookies';
import { Component } from 'react';
import cookie from 'js-cookie';
import { getDisplayName } from 'next/dist/next-server/lib/utils';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import cookies from 'next-cookies';

export const logout = () => {
	cookie.remove('token');
	cookie.remove('userId');
	cookie.remove('userName');
	Router.push('/admin/login');
};

export const login = ({ data }) => {
	const { id, username, exp, iat } = jwtDecode(data.token);

	cookie.set('token', data.token, { expires: 1 });
	cookie.set('userId', id, { expires: 1 });
	cookie.set('userName', username, { expires: 1 });

	Router.push('/admin/dashboard');
};

export const getCookies = () => {
	const token = cookie.get('token');
	const userId = cookie.get('userId');
	const userName = cookie.get('userName');

	return {
		...(token && { token }),
		...(userId && { userId }),
		...(userName && { userName })
	};
};

export const register = () => {
	// cookie.set("token", data.token, { expires: 1 });
	Router.push('/admin/login');
};

export const session = () => {
	const token = cookie.get('token');
	if (!_.isEmpty(token) && token !== 'undefined' && token !== 'null') {
		Router.push('/admin/dashboard');
	}
	return token;
};

//  Authorization helper function
export const auth = (ctx, redirect = '/admin/login') => {
	const { token } = nextCookie(ctx);
	if (ctx.req && !token) {
		ctx.res.writeHead(302, { Location: redirect });
		ctx.res.end();
		return;
	}

	if (!token) {
		Router.push(redirect);
	}

	return token;
};

/**
 * Authorization High Order Component
 * @param {*} withAuthSync
 */
export const withAuthSync = WrappedComponent =>
	class extends Component {
		static displayName = `withAuthSync(${getDisplayName(WrappedComponent)})`;

		static async getInitialProps(ctx) {
			const token = auth(ctx);

			const componentProps =
				WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx));

			return { ...componentProps, token };
		}

		render() {
			return <WrappedComponent {...this.props} />;
		}
	};
